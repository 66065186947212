<template>
	<div id="adoptList">
		<page-adopt-work-order :is-shop="0"></page-adopt-work-order>
	</div>
</template>

<script>
	import pageAdoptWorkOrder from '@/components/layout/adopt/page-adopt-work-order.vue'
	export default{
		components:{
			pageAdoptWorkOrder
		},
		inject:["reload"],		//刷新页面
	}
</script>

<style lang="scss">
</style>
